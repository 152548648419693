import "./fonts.css";

import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
} from "react";

import { getUserToken } from "@/api/createTokenProvider";
import { useLangStore } from "@/store/lang-store";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const Routes = lazy(() => import("@/layouts/Routes"));
//import Routes from "@/layouts/Routes";
const GlobalStyle = lazy(() => import("@/utils/GlobalStyle"));
//import GlobalStyle from "@/utils/GlobalStyle";
const Theme = lazy(() => import("@/utils/Theme"));
//import Theme from "@/utils/Theme";
const ScrollToTop = lazy(() => import("@/utils/ScrollToTop"));
// import ScrollToTop from "@/utils/ScrollToTop";
const CookiesComponent = lazy(() =>
  import("@/components/cookies/CookiesComponent")
);
// import CookiesComponent from "@/components/cookies/CookiesComponent";
const ModalLoginCentered = lazy(() =>
  import("@/components/Modals/ModalLoginCentered")
);
//import ModalLoginCentered from "@/components/Modals/ModalLoginCentered";
const ModalResetCentered = lazy(() =>
  import("@/components/Modals/ModalResetCentered")
);
//import ModalResetCentered from "@/components/Modals/ModalResetCentered";
const AccountCreationPopup = lazy(() =>
  import("@/components/popups/AccountCreationPopup")
);
// import AccountCreationPopup from "@/components/popups/AccountCreationPopup";
//const Cookie5Days = lazy(() => import("./utils/cookies"));
// import Cookie5Days from "./utils/cookies";

// Create a new context object using the createContext function
// This context will be used to share data between components
export const DataContext = createContext();

// The App component is the root component of our application
const App = () => {
  const [lang, setLang] = useState(null);
  const [properties, setProperties] = useState(null);
  const [routeMap, setRouteMap] = useState(null);
  const { langStore } = useLangStore();
  const [, setUserValue] = useState(getUserToken());
  const [isAccountPopupVisible, setIsAccountPopupVisible] = useState(false);
  const [displayLogin, setDisplayLogin] = useState(false);
  const [setDisplay5days] = useState(false);
  const [displayReset, setDisplayReset] = useState(false);
  const LanguesFR = useMemo(
    () => ["fr-BE", "fr-FR", "fr", "fr-CA", "fr-CH", "fr-LU", "fr-MC"],
    []
  );

  const LanguesNL = useMemo(
    () => ["nl-BE", "nl-NL", "nl", "nl-AW", "nl-BQ", "nl-CW", "nl-SX"],
    []
  );

  const LanguesEN = useMemo(
    () => ["en-US", "en-GB", "en", "en-AU", "en-CA", "en-NZ", "en-IE"],
    []
  );

  const changeAppLanguage = useCallback(() => {
    let folderLang = "";
    if (LanguesFR.includes(langStore)) {
      folderLang = "fr-BE";
    } else if (LanguesNL.includes(langStore)) {
      folderLang = "nl-BE";
    } else if (LanguesEN.includes(langStore)) {
      folderLang = "nl-BE";
    } else {
      folderLang = "nl-BE";
    }
    const textPath = `/static/${folderLang}/text.json`;
    fetch(textPath)
      .then((response) => response.json())
      .then((json) => setLang(json))
      .catch((error) => console.error(error));

    const propsPath = `/static/${folderLang}/properties.json`;
    fetch(propsPath)
      .then((response) => response.json())
      .then((json) => setProperties(json))
      .catch((error) => console.error(error));

    const routeMapPath = `/static/${folderLang}/route.json`;
    fetch(routeMapPath)
      .then((response) => response.json())
      .then((json) => setRouteMap(json))
      .catch((error) => console.error(error));
  }, [LanguesEN, LanguesFR, LanguesNL, langStore]);

  // Call the getCurrentLanguage function when the component mounts
  useEffect(() => {
    // setLangStore(localStorage.getItem('lang') || 'nl-BE');
    // Respond to the `storage` event
    changeAppLanguage();
  }, [changeAppLanguage]);

  // Log the data and lang variables to the console for debugging purposes
  const toggleLoginPopup = useCallback(
    () => setDisplayLogin((prev) => !prev),
    [setDisplayLogin]
  );
  const closeAllPopup = useCallback(() => {
    setDisplayReset(false);
    setDisplayLogin(false);
    setIsAccountPopupVisible(false);
  }, [setDisplayReset, setDisplayLogin, setIsAccountPopupVisible]);
  const toggleResetPopup = useCallback(
    () => setDisplayReset((prev) => !prev),
    [setDisplayReset]
  );
  const toggleDisplay5days = useCallback(
    () => setDisplay5days((prev) => !prev),
    [setDisplay5days]
  );
  const handleStorage = useCallback(() => {
    const userToken = getUserToken();
    setUserValue(userToken);
  }, [setUserValue]);
  const toggleAccountPopup = useCallback(
    () => setIsAccountPopupVisible((prev) => !prev),
    [setIsAccountPopupVisible]
  );

  useEffect(() => {
    window.addEventListener("tokenUserChanged", handleStorage);
    return () => window.removeEventListener("tokenUserChanged", handleStorage);
  }, [handleStorage]);

  useEffect(() => {
    window.addEventListener("ToogleAccountPopup", toggleAccountPopup);
    return () =>
      window.removeEventListener("ToogleAccountPopup", toggleAccountPopup);
  }, [toggleAccountPopup]);

  useEffect(() => {
    window.addEventListener("ToggleLoginForm", toggleLoginPopup);
    return () =>
      window.removeEventListener("ToggleLoginForm", toggleLoginPopup);
  }, [toggleLoginPopup]);

  useEffect(() => {
    window.addEventListener("CloseAnyForm", closeAllPopup);
    return () => window.removeEventListener("CloseAnyForm", closeAllPopup);
  }, [closeAllPopup]);

  useEffect(() => {
    window.addEventListener("ToggleResetForm", toggleResetPopup);
    return () =>
      window.removeEventListener("ToggleResetForm", toggleResetPopup);
  }, [toggleResetPopup]);

  useEffect(() => {
    window.addEventListener("ToggleDisplay5days", toggleDisplay5days);
    return () =>
      window.removeEventListener("ToggleDisplay5days", toggleDisplay5days);
  }, [toggleDisplay5days]);

  useEffect(() => {
    if (isAccountPopupVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isAccountPopupVisible]);

  return (
    <Theme>
      <BrowserRouter>
        <Suspense fallback={<>loading...</>}>
          <ScrollToTop />
          <GlobalStyle />
          <DataContext.Provider value={{ lang, properties, routeMap }}>
            <CookiesProvider>
              <HelmetProvider>
                <CookiesComponent />
                {/* <Cookie5Days /> */}
                {isAccountPopupVisible && (
                  <AccountCreationPopup
                    setIsAccountPopupVisible={setIsAccountPopupVisible}
                  />
                )}
                {displayLogin && <ModalLoginCentered />}
                {displayReset && <ModalResetCentered />}

                {<Routes />}
              </HelmetProvider>
            </CookiesProvider>
          </DataContext.Provider>
        </Suspense>
      </BrowserRouter>
    </Theme>
  );
};

export default App;
